import accountsData from './data/feedAccountDetail.json'
import migratingAccountResponse from './data/migrateFinaliseData.json'
import migrateresult from './data/migrateResult.json'
import applicationData from './data/applicationData.json'
import passcode from './data/passcode.json'
import onboardSummaryPartialFailure from './data/onboardSummaryPartialFailure.json'
import onboardSummaryAllSuccess from './data/onboardSummaryAllSuccess.json'
import onboardSummaryAllFailure from './data/onboardSummaryAllFailure.json'

const fetchApplicationData = async (code) => {
  return applicationData;
}

const fetchRedirectURL = async (requestBody) => {
  return "/onboard/finalise?code=1234&state=YXBwbGljYXRpb25JZD1hcHBsaWNhdGlvbklkJnByb2R1Y3RSZWRpcmVjdFVybD1odHRwczovL2dvb2dsZS5jb20&source=ProprietaryAPI";
};

const fetchAccountDataForMigration = async () => {
  return migratingAccountResponse;
}

const fetchAccountData = async (code, state, source) => {
  if (code === 'error') throw new Error('error')
  if (code === 'allOnboarded') return { ...accountsData, accounts: accountsData.accounts
      .map(account =>
        {
          account.onboarded = true
          return account
        })
  }
  if (code === 'noAvailableFeed') return { ...accountsData, accounts: accountsData.accounts, bankfeedLimit: 0, availableFeeds: 0}
  return accountsData;
};

const fetchPasscode = async (code) => {
  return passcode;
};

const getBankingPortalUrl = async (code) => {
  return "/"
}

const createFeeds = async (applicationId, accounts) => {}

const cancelApplication = async (applicationId) => {}

const fetchOnboardSummary = async (applicationId, consumptionId) => {

  if (consumptionId === "partialFailure")
    return onboardSummaryPartialFailure;
  if (consumptionId === "allFailure")
    return onboardSummaryAllFailure;

  return onboardSummaryAllSuccess;
};

const migrateFeeds = async () => {
  return migrateresult
}

export {
  fetchApplicationData,
  fetchRedirectURL,
  fetchAccountData,
  fetchPasscode,
  fetchOnboardSummary,
  getBankingPortalUrl,
  cancelApplication,
  createFeeds,
  fetchAccountDataForMigration,
  migrateFeeds
}
